<template>
  <CampaignChecker dialog-name="Campaign Checker" />
</template>
<script>
import CampaignChecker from '@/views/app/checker/Campaign'

export default {
  name: 'CampaignWrapper',
  components: {
    CampaignChecker
  }
}
</script>
